/* src/fonts.css */
@font-face {
    font-family: 'GothamRounded';
    src: url('../public/fonts/gothamrnd_medium.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'GothamRounded';
    src: url('../public/fonts/gothamrnd_bold.otf') format('opentype');
    font-weight: bold;
    font-style: normal;
  }
  